import { User, UserApi } from "@ist/authenticated-user-api-typescript-axios";
import { settings } from "../settings";

export interface UserService {
  getUser(jwtToken: string | undefined): Promise<User>;
}

export class UserServiceImpl implements UserService {
  private userApi: UserApi | undefined;

  constructor() {
    if (settings.authenticatedUserBasePath.length > 0) {
      this.userApi = new UserApi(undefined, settings.authenticatedUserBasePath);
    }
  }

  public getUser(jwtToken: string | undefined): Promise<User> {
    const headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    };
    const options = {
      headers,
    };
    if (this.userApi) {
      return new Promise<User>((resolve: any, reject: any) => {
        reject("User API not available");
      });
    }
    return new Promise<User>((resolve: any, reject: any) => {
      return this.userApi!.getUser(options)
        .then((userResponse) => {
          resolve(userResponse.data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}
