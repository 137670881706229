import {
  ReactNode,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import { SessionContext } from "@ist-group-private-scope/web-skolid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosError } from "axios";
import { settings } from "../settings";
import { httpGET } from "./api";

export interface SourceInfoContextValue {
  sourceInfo: Sources;
  sourceInfoLoading: boolean;
  sourceInfoReload: () => void;
}
const SourceInfoContext = createContext<SourceInfoContextValue>({
  sourceInfo: new Map(),
  sourceInfoLoading: false,
  sourceInfoReload: () => undefined,
});

interface ErrorMessageDisplayProps {
  errorTitle: string;
  errorMessage: string;
  retry?: () => void;
}
function ErrorMessageDisplay({
  errorTitle,
  errorMessage,
  retry,
}: ErrorMessageDisplayProps): ReactElement {
  const session = useContext(SessionContext);
  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle>{errorTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {retry && (
          <Button color="primary" onClick={retry}>
            Retry
          </Button>
        )}
        <Button color="primary" onClick={() => session.logout()}>
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface SourceInfoProviderProps {
  children: ReactNode;
}

export function SourceInfoProvider({
  children,
}: SourceInfoProviderProps): ReactElement {
  const [value, setValue] = useState<Sources>();
  const lodingRef = useRef(false);
  const [error, setSourcesError] = useState<AxiosError | undefined>();
  const session = useContext(SessionContext);
  const jwt = session?.user?.access_token;

  const getSourceInfo = useCallback(() => {
    lodingRef.current = true;
    setSourcesError(undefined);
    httpGET<SourceInfo[]>(settings.apiHost + "/accessInfo", jwt)
      .then((result) => {
        lodingRef.current = false;
        setValue(new Map(result.data?.map((s) => [s.id, s] ?? [])));
      })
      .catch((e) => {
        setSourcesError(e);
        lodingRef.current = false;
      });
  }, [jwt]);

  useEffect(() => {
    if (!value && !lodingRef.current && !error && jwt) {
      getSourceInfo();
    }
  }, [getSourceInfo, value, error, jwt]);

  return (
    <SourceInfoContext.Provider
      value={{
        sourceInfo: value ?? new Map(),
        sourceInfoLoading: lodingRef.current,
        sourceInfoReload: getSourceInfo,
      }}
    >
      {error ? (
        <ErrorMessageDisplay
          errorTitle={error.name}
          errorMessage={
            error.response?.status === 403
              ? "You don't have access to any datasources or vendors. Contact your IT-Administrator or IST Support to resolve the issue."
              : error.message
          }
          retry={getSourceInfo}
        ></ErrorMessageDisplay>
      ) : (
        children
      )}
    </SourceInfoContext.Provider>
  );
}

export interface WorkStatus {
  sourceId: string;
  time: string;
  status:
    | "updating"
    | "isi_fetch"
    | "isi_fetch_queued"
    | "idle"
    | "error"
    | "deleting"
    | "deleted"
    | "queued";
  instance: string;
  instanceName: string;
  errors: Array<{
    instanceName: string;
    message: string;
    time: Date;
  }>;
  processorQueue?: string;
}

export interface Client {
  id: string;
  name: string;
  admins: string[];
  dataPackets: DataPackage[];
  product?: {
    id: string;
    name: string;
    vendor?: {
      id: string;
      name: string;
    };
  };
}

export interface DataPackage {
  id: DataPackageId;
  name?: string;
  description?: string;
}

export enum DataPackageId {
  "ist.ss12000-v2.api.student",
  "ist.ss12000-v2.api.responsibles",
  "ist.ss12000-v2.api.group",
  "ist.ss12000-v2.api.placements",
  "ist.ss12000-v2.api.duty",
  "ist.ss12000-v2.api.studyplan",
  "ist.ss12000-v2.api.grade",
}

export interface SourceInfo {
  id: string;
  description: string;
  organisation?: {
    id: string;
    name: string;
  };
  clients: Client[];
  datasourceInfo?: CacheDataInfo;
  workStatus?: WorkStatus;
  role?: "SUPER_ADMIN" | "ADMIN" | "VENDOR_ADMIN";
}

interface CacheDataInfo {
  host: string;
  basePort: string;
  sourceId: string;
  cacheId: string;
  time: string;
  instanceId: string;
  instanceName: string;
  dataFormatVersion: string;
  stats?: DataStats;
}
export interface DataStats {
  timing?: {
    snapshotStart: string;
    snapshotEnd: string;
  };
  count?: DataStatsCount;
  errorStats?: {
    errorMessage: string;
  };
}

export type Entity =
  | "Absence"
  | "AttendanceEvent"
  | "Attendance"
  | "Grade"
  | "CalendarEvent"
  | "AttendanceSchedule"
  | "Resource"
  | "Room"
  | "Activity"
  | "Duty"
  | "Placement"
  | "StudyPlan"
  | "Programme"
  | "Syllabus"
  | "SchoolUnitOffering"
  | "Group"
  | "Person"
  | "Organisation";

export type DataStatsCount = {
  [k in Entity]: {
    new: number;
    updated: number;
    deleted: number;
    same: number;
  };
};
export type Sources = Map<string, SourceInfo>;

export const useSourceInfo = (): SourceInfoContextValue => {
  return useContext(SourceInfoContext);
};
export const useSourceInfoList = (
  role?: string,
  customerId?: string
): SourceInfoContextValue & { sourceInfoList: SourceInfo[] | undefined } => {
  const sourceInfo = useSourceInfo();

  const sourceInfoList = useMemo(() => {
    return sourceInfo.sourceInfo
      ? Array.from(sourceInfo.sourceInfo.values()).filter(
          (v) =>
            (!customerId ||
              v.organisation?.id === customerId ||
              customerId === "undefined") &&
            (!role || v.role === role || v.role === "SUPER_ADMIN")
        )
      : undefined;
  }, [sourceInfo, role, customerId]);
  return { ...sourceInfo, sourceInfoList };
};
export const requestSourceRefresh = async (sourceId: string, jwt: string) =>
  httpGET(
    settings.apiHost +
      "/requestRefresh?sourceId=" +
      encodeURIComponent(sourceId),
    jwt
  )
    .then((_res) => "Source update requested for: " + sourceId)
    .catch((err) => {
      console.warn(
        "Source update requested failed. Response data: %o",
        err.response?.data
      );
      throw err;
    });

export const requestCacheDelete = async (sourceId: string, jwt: string) =>
  httpGET(
    settings.apiHost +
      "/requestCacheDelete?sourceId=" +
      encodeURIComponent(sourceId),
    jwt
  )
    .then((_res) => "Cache delete requested for: " + sourceId)
    .catch((err) => {
      console.warn(
        "Cache delete requested failed. Response data: %o",
        err.response?.data
      );
      if (err.response) {
        return err.response?.data?.message
          ? err.response?.data?.message
          : err.response.statusText;
      }
      return err.message;
    });
