import { ReactElement, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import MaterialTable from "@material-table/core";
import { useParams } from "react-router-dom";
import {
  DataBoxBlock,
  DataBoxSection,
  PropertyItem,
  Section,
  TimePassedView,
  TimeView,
} from "../common/generic";
import { useSourceInfo } from "../common/sourceInfo";
import { useAccessItems } from "../common/sessionInfo";

export interface SourceViewProps {
  refreshSource: (sourceId: string) => void;
  deleteCache: (sourceId: string) => void;
}
export default function SourceView({
  refreshSource,
  deleteCache,
}: SourceViewProps): ReactElement {
  const params = useParams();
  const sourceId = (params as any).sourceId;
  const { sourceInfo, sourceInfoLoading } = useSourceInfo();
  const accessItems = useAccessItems();

  const handleRefreshSourceButtonClick = useCallback(() => {
    refreshSource(sourceId);
  }, [refreshSource, sourceId]);
  const handleDeleteCacheButtonClick = useCallback(() => {
    deleteCache(sourceId);
  }, [deleteCache, sourceId]);

  if (!sourceId) {
    return (
      <Box pt={8}>
        <Section name="Not found!">
          No Source id supplied<pre>{JSON.stringify(params, null, 2)}</pre>
        </Section>
      </Box>
    );
  }

  if (!sourceInfo || sourceInfoLoading) {
    return (
      <Box pt={8}>
        <Section name="Loading" loading={sourceInfoLoading}>
          ...
        </Section>
      </Box>
    );
  }

  const source = sourceInfo.get(sourceId);
  if (!source) {
    return (
      <Box pt={8}>
        <Section name="Not found!">
          <Alert severity="error">Could not find: {sourceId}</Alert>
        </Section>
      </Box>
    );
  }

  const countList = source.datasourceInfo?.stats?.count
    ? Object.entries(source.datasourceInfo?.stats?.count).map((entry) => ({
        item: entry[0],
        ...entry[1],
      }))
    : undefined;
  return (
    <Box pt={8}>
      <Section name={source?.organisation?.name}>
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItem title="Organisation">
              {source.organisation?.name}
            </PropertyItem>
            <PropertyItem title="Subscription">
              {source.organisation?.id}
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="Host">
              {source.datasourceInfo?.host}
            </PropertyItem>
            <PropertyItem title="Instance">
              {source.datasourceInfo?.instanceName}
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="Data Version">
              {source.datasourceInfo?.dataFormatVersion}
            </PropertyItem>
            <PropertyItem title="Status">
              {source.workStatus?.status ?? "idle"}{" "}
              <TimePassedView date={source.workStatus?.time} />
              {source.workStatus?.processorQueue
                ? " (" + source.workStatus?.processorQueue + ") "
                : ""}
            </PropertyItem>
          </DataBoxBlock>
        </DataBoxSection>
        {source.datasourceInfo?.stats?.timing ? (
          <DataBoxSection>
            <DataBoxBlock>
              <PropertyItem title="Build start time">
                <TimeView
                  date={source.datasourceInfo.stats.timing.snapshotStart}
                />
              </PropertyItem>
              <PropertyItem title="Build end time">
                <TimeView
                  date={source.datasourceInfo.stats.timing.snapshotEnd}
                />
              </PropertyItem>
            </DataBoxBlock>
            <DataBoxBlock>
              <PropertyItem title="Duration">
                <TimePassedView
                  date={source.datasourceInfo.stats.timing.snapshotStart}
                  now={source.datasourceInfo.stats.timing.snapshotEnd}
                />
              </PropertyItem>
            </DataBoxBlock>
          </DataBoxSection>
        ) : null}
        {(source.workStatus?.errors?.length ?? 0) > 0 ? (
          <DataBoxSection>
            <DataBoxBlock>
              <PropertyItem title="Error history">
                {source.workStatus?.errors.map((errorPost, i) => (
                  <Alert
                    key={i}
                    severity="error"
                    title={errorPost.instanceName}
                  >
                    {`${errorPost.time} - ${errorPost.message}`}
                  </Alert>
                ))}
              </PropertyItem>
            </DataBoxBlock>
          </DataBoxSection>
        ) : null}

        <Box px={4} py={2}>
          {accessItems?.length === 0 ||
          accessItems?.includes("/source-settings#update") ? (
            <Button
              color="primary"
              onClick={handleRefreshSourceButtonClick}
              startIcon={<BackupIcon />}
              variant="contained"
            >
              Request source refresh
            </Button>
          ) : null}
          &nbsp;&nbsp;
          {accessItems?.length === 0 ||
          accessItems?.includes("/source-settings#delete") ? (
            <Button
              color="primary"
              onClick={handleDeleteCacheButtonClick}
              startIcon={<DeleteIcon />}
              variant="contained"
            >
              Request cache removal
            </Button>
          ) : null}
        </Box>
      </Section>
      {countList ? (
        <Box pt={4}>
          <MaterialTable
            title="Item Count"
            columns={[
              { field: "item", title: "Entity" },
              {
                field: "same",
                title: "Same",
                type: "numeric",
                defaultSort: "desc",
              },
              { field: "new", title: "New", type: "numeric" },
              { field: "updated", title: "Updated", type: "numeric" },
              { field: "deleted", title: "Deleted", type: "numeric" },
            ]}
            // other props
            options={{
              minBodyHeight: 25,
              filtering: true,
              emptyRowsWhenPaging: false,
              pageSize: 20,

              // sorting: undefined can be removed at some point.
              // There's a bug in MaterialTable that causes it to complain that
              // "Property `sorting` has been deprecated, please start using `maxColumnSort` instead."
              // if sorting is NOT set
              sorting: undefined,
            }}
            data={countList}
          />
        </Box>
      ) : undefined}
    </Box>
  );
}
