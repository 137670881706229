import { SessionContext } from "@ist-group-private-scope/web-skolid";
import { StylistHeader } from "../common/stylist/header/StylistHeader";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { ReactElement, useCallback, useContext, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SourceBasedBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { UserMenu } from "../common/generic";
import {
  requestCacheDelete,
  requestSourceRefresh,
  useSourceInfo,
} from "../common/sourceInfo";
import SourceView from "./SourceView";
import SourceList from "./SourceList";

export default function SourceSettingsContent(): ReactElement {
  const match = useRouteMatch();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [level, setLevel] = useState<AlertColor>("info");

  const showAlert = (newMessage: string, newSeverity: AlertColor) => {
    setMessage(newMessage);
    setLevel(newSeverity);
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  const session = useContext(SessionContext);
  const jwt = session?.user?.access_token;
  const { sourceInfoReload } = useSourceInfo();

  const refreshSource = useCallback(
    async (sourceId: string) => {
      let message: string = "No access!";
      let success = false;
      if (jwt) {
        try {
          message = await requestSourceRefresh(sourceId, jwt);
          success = true;
        } catch (err: any) {
          if (err.response) {
            message = err.response?.data?.message
              ? err.response?.data?.message
              : err.response.statusText;
          } else {
            message = err.message;
          }
        }
        if (success) {
          // Potential issue: Do we have a race condition here?
          // If it takes more than 2 seconds to refresh, we'll get the old data?
          setTimeout(sourceInfoReload, 2000);
        }
      }
      showAlert(message, success ? "success" : "error");
    },
    [jwt, sourceInfoReload]
  );

  const deleteCache = useCallback(
    async (sourceId: string) => {
      let message: string = "No access!";
      let success = false;
      if (jwt) {
        try {
          message = await requestCacheDelete(sourceId, jwt);
          success = true;
        } catch (err) {
          message =
            "Error requesting cache delete " +
            (err instanceof Error ? err.message : String(err));
        }
        if (success) {
          setTimeout(sourceInfoReload, 2000);
        }
      }
      showAlert(message, success ? "success" : "error");
    },
    [jwt, sourceInfoReload]
  );

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={hideAlert}>
        <Alert severity={level} onClose={hideAlert}>
          {message}
        </Alert>
      </Snackbar>
      <StylistHeader>
        <SourceBasedBreadcrumbs name={"Manage Sources"} />
        <Grid item style={{ flexGrow: 1 }} />
        <UserMenu />
      </StylistHeader>
      <Switch>
        <Route exact path={match.path}>
          <SourceList refreshSource={refreshSource} />
        </Route>
        <Route path={`${match.path}/:sourceId`}>
          <SourceView refreshSource={refreshSource} deleteCache={deleteCache} />
        </Route>
      </Switch>
    </>
  );
}
